// import { findLocalItems } from "../utils/util";
import Cookies from "js-cookie";
import {
  getCurrentSession,
  getCurrentAuthenticatedUser,
} from "../services/auth.service";
import { hasMatchingWord } from "../utils/util";

export async function fetchPatients(isInitialLoad, page, limit) {
  const user = await getCurrentAuthenticatedUser();
  const formapiurl = `${user?.attributes["custom:gateway"]}/formapi/api`;

  let FORM_API_URL = formapiurl + "/series";

  if (isInitialLoad) {
    FORM_API_URL += `?page=${page}&limit=${limit}`;
  }

  // ensure fresh tokens
  const session = await getCurrentSession();

  // if no user logged in
  if (session == null) {
    var strClientAccessToken = Cookies.get("ClientAccessToken");
  } else strClientAccessToken = session.accessToken.jwtToken;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    accesstoken: strClientAccessToken,
  };

  // for testing  const newFormAPIPatients = [];

  const newFormAPIPatients = await fetch(FORM_API_URL, {
    method: "GET",
    headers: {
      ...headers,
    },
    /*    credentials: 'include' // include cookies in the request */
  })
    .then(handleErrors)
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  if (
    newFormAPIPatients instanceof Error /* || oldAPIPatients instanceof Error */
  ) {
    return [];
  }

  const patients = isInitialLoad
    ? [...newFormAPIPatients?.items]
    : [...newFormAPIPatients];

  const clinicName = user?.attributes?.["custom:clinicName"];

  // only admin-confirmed user AND
  // non-SSO clinic users
  // should only see and be able to filter authPatients
  // authPatients are all patients for user's clinic (matched to Cognito formapiurl) or
  // learners should only see their own studies for their clinic (matched to Cognito formapiurl)
  // educators can see all learners studies
  // all patients with an immutable value (e.g. PatientID) that matches a user custom attribute (e.g. signatureName)
  // non-clinic / non-demo (e.g. external) users will not see studies with no patientID

  // demo environment users are same as non-demo environment users

  const authPatients = patients.filter(
    (patient) =>
      // must be adminConfirmed &&
      user?.attributes["custom:adminConfirmed"] &&
      // 1 of the 3 following possibilities
      // 1 user clinic is not sso, demo or prime
      ((!hasMatchingWord(clinicName, "sso") &&
        !hasMatchingWord(clinicName, "demo") &&
        // and study isn't a test in progress by a learner
        // or the user is an Educator
        // or it's not the case that the study is not a test in progress by this learner
        // (i.e. the study is a test in progress by this learner)
        (!patient.LearnerID ||
          user?.attributes["custom:role"] === "Educator" ||
          // hide test reports of other learners from the current Learner
          !(patient.LearnerID !== user?.attributes["custom:learnerID"]))) ||
        // or 2 user is not a cardiologist
        // or it's not the case that the study is In progress
        // and the user's signatureName does not match the study's Physician of Record
        // (i.e. the study is In progress and the user's signatureName matches the study's Physician of Record)
        user?.attributes["custom:role"] !== "cardiologist" ||
        !(
          patient.studyStatus === "In progress" &&
          !user?.attributes["custom:signatureName"]?.includes(
            patient.PhysiciansOfRecord?.slice(-4)
          )
        ) ||
        // 3 user has signatureName matching one or more study PatientIDs
        (patient.PatientID &&
          patient.PatientID === user?.attributes["custom:signatureName"]))
  );

  console.log("authPatients", authPatients);

  // Source: https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
  // Sort based on SeriesDate then by SeriesTime
  const sortedPatients = authPatients.sort((a, b) =>
    a.SeriesDate > b.SeriesDate
      ? 1
      : a.SeriesDate === b.SeriesDate
      ? a.SeriesTime > b.SeriesTime
        ? 1
        : -1
      : -1
  );
  sortedPatients.reverse();

  return Promise.resolve(
    sortedPatients.map((patient) => {
      // calculate the slug
      patient.slug = patient.SeriesInstanceUID;
      patient.studyIUID = patient.StudyInstanceUID;

      return patient;
    })
  );
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
